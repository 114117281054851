<template>
  <button
    class="
      p0 bg-cl-primary relative
      brdr-square h5 cl-black weight-400 size-selector
    "
    :style="colorFrom"
    :class="{ active: isActive }"
    @click="$emit('change', variant), setColor(ColorFromPallete())"
    :aria-label="variant.label"
  >
    <span class="hover-label absolute">
      <span class="h4 color" :style="colorFrom" />
      <span>Nazwa: {{ ColorFromPallete() ? ColorFromPallete().Nazwa : variant.label }}
      </span>
      <no-ssr>
        <span v-if="ColorFromPallete()">{{ ColorFromPallete().IDKoloruMagento && `Nasz kod: ${ColorFromPallete().IDKoloruMagento}` }}</span>
      </no-ssr>
    </span>
  </button>
</template>

<script>
import filterMixin from 'theme/mixins/filterMixin.ts'
import { getGroupedColors } from 'theme/utils/getColors'
import NoSSR from 'vue-no-ssr'
import config from 'config'

export default {
  props: {
    brand: {
      type: [String, Boolean],
      default: false
    },
    palette: {
      type: [Array, Boolean],
      default: false
    }
  },
  components: {
    'no-ssr': NoSSR
  },
  beforeDestroy () {
    this.$store.dispatch('ui/unsetChosenColor')
  },
  computed: {
    colorMapping () {
      return this.getGroupedColors('Marka', this.brand)
    },
    colorFrom () {
      if (this.ColorFromPallete() && this.ColorFromPallete().HEX) {
        return `background-color: #${this.ColorFromPallete().HEX}`
      } else if (this.ColorFromPallete() && config.products.colorMappings[this.ColorFromPallete().KolorProsty]) {
        return `background-color: ${config.products.colorMappings[this.ColorFromPallete().KolorProsty]}`
      } else {
        return 'background-color: transparent;'
      }
    }
  },
  methods: {
    getGroupedColors: getGroupedColors,
    ColorFromPallete () {
      let everyVarIsNotEmpty = [
        this.brand,
        this.palette,
        this.variant.label.toLowerCase(),
        this.colorMapping,
        this.colorMapping[this.brand.toLowerCase()]
      ].every(x => x)
      if (!everyVarIsNotEmpty) { return null }
      let allPaletteColors = {}
      for (let paletteElem of this.palette) {
        Object.assign(allPaletteColors, this.colorMapping[this.brand.toLowerCase()][paletteElem])
      }
      if (Object.keys(allPaletteColors).length && allPaletteColors[this.variant.label.toLowerCase()]) {
        return allPaletteColors[this.variant.label.toLowerCase()][0]
      } else {
        return null
      }
    },
    setColor (color) {
      this.$store.dispatch('ui/setChosenColor', color)
    }
  },
  mixins: [filterMixin]
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-active: color(primary-orange);
  $color-disabled: color(secondary, $colors-border);
  $color-text: color(black);

  .size-selector {
    width: fit-content;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 0;
    margin-bottom: 5px;
    margin-top: 5px;
    box-shadow: 1px 1px 4px 0px;
    // &:hover,
    &:focus {
      box-shadow: 0px 0px 0px 2px #ff8100;
    }

    &.active {
      box-shadow: 0px 0px 0px 2px #ff8100;
    }

    &:disabled {
      border: 1px solid #000000;
      color: $color-disabled;
      cursor: not-allowed;
    }
    &.not-eccomerce {
      display: none;
    }
    &.disabled {
      pointer-events: none;
      color: $color-disabled;
      cursor: not-allowed;
      box-shadow: none;
      opacity: 0.2;
      .hover-label {
        display: none !important;
      }
    }
    &:hover {
      @media (min-width: 787px) {
        .hover-label {
          display: flex;
        }
      }
    }
    .hover-label {
      min-width: 100px;
      left: -50%;
      bottom: 45px;
      font-size: 12px;
      background-color: white;
      padding: 5px;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      display: none;
      flex-wrap: wrap;
      justify-content: center;
      .color {
        margin-bottom: 5px;
        width: 65px;
        height: 65px;
      }
    }
  }
  .mr-auto {
    margin-right: auto;
  }
</style>
